.Parent {
    cursor: pointer;
    position: relative;
}
.Parent:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.15);
    opacity: 0;
    transition: opacity 0.4s ease;
}
.Parent:hover:after,
.Open.Parent:after {
    opacity: 1;
}

.Child > div {
    background: #D8E6F3!important;
    color: #161616!important;
}

/*.Source {*/
/*    border-bottom: 1px solid rgb(29 35 39);*/
/*}*/
/*.Source:last-child {*/
/*    border-bottom: 0;*/
/*}*/
/*.Source.Header {*/
/*    background-color: red;*/
/*}*/

/*.Source > .Row {*/
/*    padding: 5px;*/
/*    cursor: pointer;*/
/*    transition: background-color 0.4s ease;*/
/*    display: flex;*/
/*    overflow: hidden;*/
/*}*/

/*.Source:not(.Open):hover  > .Row {*/
/*    background-color: rgb(242 242 242);*/
/*}*/

/*.Source .Name {*/
/*    width: 200px;*/
/*    text-overflow: ellipsis;*/
/*    overflow: hidden;*/
/*}*/

/*.Source .Children {*/
/*    height: 0;*/
/*    overflow: hidden;*/
/*    -moz-animation-delay: 3.5s;*/
/*    -webkit-animation-delay: 3.5s;*/
/*    -o-animation-delay: 3.5s;*/
/*    animation-delay: 3.5s;*/
/*}*/
/*.Source.Open .Children {*/
/*    height: auto;*/
/*}*/
/*.Source .Children .Row {*/
/*    padding: 5px;*/
/*    display: flex;*/
/*    border-top: 1px solid rgb(176 191 198);*/
/*}*/
