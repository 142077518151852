.mainContentWrapper {
    border: 1px solid #adafae;
    border-radius: 5px;
    margin-top: 15px;
    overflow-x: hidden;
}
.offers-list-reload{
    float: right;
    cursor: pointer;
    color: #0D6064;
    margin-right: 25px;
    font-weight: bold;

}
.input-block{
    padding: 0 5px;
}
.input-block input, .input-block select{
    background-color: #ffffff !important;
}
.input-block input[disabled], .input-block select[disabled]{
    background-color: #efefef !important;
}
.offer-data{
    margin: 5px;
    padding: 15px 5px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
}
.border-bottom{
    border-bottom: 1px solid;
    border-color: #cccccc !important;
}
