.loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(91.04deg, #8E0FE1 32.81%, #BB3AF8 100%);
    z-index: 1000;
    overflow: hidden;
}

.loaderWrapper .logo {
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 15px;
}

.appSpinner {
    width: 92px;
    height: 92px;
    position: relative;
}

.appSpinner .appSpinnerElement {
    width: 92px;
    height: 50px;
    border-radius: 24.8558px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
}
.appSpinner .appSpinnerElement:nth-child(1) {
    border: 8.68176px solid #FFFFFF;
    animation: matrixSpinner infinite linear 2s;
}
.appSpinner .appSpinnerElement:nth-child(2) {
    border: 8.68176px solid rgba(255,255,255,0.65);
    animation: matrixSpinnerReverse infinite linear 2s;
}

@keyframes matrixSpinner {
    0% {
        transform: rotate(-60deg) translateX(25px);
        -ms-transform: rotate(-60deg) translateX(25px);
        -webkit-transform: rotate(-60deg) translateX(25px);
    }
    25% {
        transform: rotate(0deg) translateX(0px);
        -ms-transform: rotate(0deg) translateX(0px);
        -webkit-transform: rotate(0deg) translateX(0px);
    }
    50% {
        transform: rotate(60deg) translateX(-25px);
        -ms-transform: rotate(60deg) translateX(-25px);
        -webkit-transform: rotate(60deg) translateX(-25px);
    }
    75% {
        transform: rotate(0deg) translateX(0px);
        -ms-transform: rotate(0deg) translateX(0px);
        -webkit-transform: rotate(0deg) translateX(0px);
    }
    100% {
        transform: rotate(-60deg) translateX(25px);
        -ms-transform: rotate(-60deg) translateX(25px);
        -webkit-transform: rotate(-60deg) translateX(25px);
    }
}
@keyframes matrixSpinnerReverse {
    0% {
        transform: rotate(-120deg) translateX(25px);
        -ms-transform: rotate(-120deg) translateX(25px);
        -webkit-transform: rotate(-120deg) translateX(25px);
    }
    25% {
        transform: rotate(-180deg) translateX(0px);
        -ms-transform: rotate(-180deg) translateX(0px);
        -webkit-transform: rotate(-180deg) translateX(0px);
    }
    50% {
        transform: rotate(-235deg) translateX(-25px);
        -ms-transform: rotate(-235deg) translateX(-25px);
        -webkit-transform: rotate(-235deg) translateX(-25px);
    }
    75% {
        transform: rotate(-180deg) translateX(0px);
        -ms-transform: rotate(-180deg) translateX(0px);
        -webkit-transform: rotate(-180deg) translateX(0px);
    }
    100% {
        transform: rotate(-120deg) translateX(25px);
        -ms-transform: rotate(-120deg) translateX(25px);
        -webkit-transform: rotate(-120deg) translateX(25px);
    }
}