.app__field__row {
    display: flex;
    align-items: center;
}
.app__field__row  .app__select__label {
    width: 145px;
}
.app__field__row .app__select {
    position: relative;
}
.app__field__row  .app__select {
    width: 100%;
}
.app__field__row  .app__select__label+.app__select {
    width: calc(100% - 145px);
}
.app__select select {
    display: block;
    width: 100%;
    padding: 9px 15px;
    background-color: #F2F2F2;
    border: 1px solid #B0BFC6;
    box-sizing: border-box;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    outline: 0;
    font-size: 16px;
    color: #B0BFC6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.app__select select.error {
    border-color: #D62D30!important;
}
.app__select select option:disabled {
    color: #B0BFC6;
}
.app__select select option {
    color: #151515;
}
.app__select.selected select {
    color: #151515;
}
.app__select select:focus {
    border-color: #1998A7!important;
}

@media(max-width: 500px){
    .app__field__row {
        flex-direction: column;
        margin-bottom: 15px;
    }
    .app__field__row  .app__select__label {
        width: 100%;
        margin-bottom: 5px;
    }
    .app__field__row  .app__select__label+.app__select {
        width: 100%;
    }
    .app__select select{
        font-size: 14px;
        line-height: 19px;
    }
}
