.spinner {
    display: flex;
    position: relative;
    animation: rotateLoad infinite linear 1s;
}

.spinner { width: 30px; height: 30px; }
.spinner.small { width: 15px; height: 15px; }
.spinner:before,
.spinner:after {
    display: flex;
    position: absolute;
    content: '';
    background: #1998A7;
    border-radius: 50%;
    width: 55%;
    height: 55%;
}
.spinner:before { right: 0; bottom: 0; animation: scaleChange_1 infinite linear 1s; }
.spinner:after { top: 0; left: 0; animation: scaleChange_2 infinite linear 1s; }

@keyframes rotateLoad {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}
@keyframes scaleChange_1 {
    0%    { transform: scale(1); }
    50%   { transform: scale(0); }
    100%  { transform: scale(1); }
}
@keyframes scaleChange_2 {
    0%    { transform: scale(0); }
    50%   { transform: scale(1); }
    100%  { transform: scale(0); }
}