.Statistics h1 {
    margin: 20px 0 0;
}

.Statistics .Form {
    display: flex;
    gap: 15px;
    margin: 20px 0 10px;
}

.Statistics .Data {
    min-height: 500px;
    position: relative;
    font-size: 13px;
    margin-bottom: 20px;
}

.Statistics .Table {
    display: table;
    font-size: 14px;
    border-collapse: collapse;
    text-align: center;
    width: 100%;
    table-layout: fixed;
}
.Statistics .Table .tr {
    display: table-row;
}
.Statistics .Table .th,
.Statistics .Table .td:first-child {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    background: #AFCDE7;
    color: white;
    padding: 10px 20px;
}
.Statistics .Table .th,
.Statistics .Table .td {
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    font-weight: bold;
}

.Statistics .Table .td {
    background: #D8E6F3;
}
.Statistics .Table .th:first-child,
.Statistics .Table .td:first-child {
    text-align: left;
}
