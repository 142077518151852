.textareaApp {
    position: relative;
    margin-bottom: 25px;
}
.textareaApp textarea {
    resize: none;
    display: block;
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #B0BFC6;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.4s ease;
    color: #151515;
    min-height: 120px;
    max-height: 200px;
    background: #F2F2F2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.textareaApp textarea:focus {
    border-color: #1998A7!important;
}
.textareaApp textarea::placeholder {
    color: #B0BFC6;
}
.textareaApp.error textarea {
    border-color: #D62D30!important;
}

@media(max-width: 991px){
    .textareaApp {
        margin-bottom: 15px;
    }
}

@media(max-width: 500px){
    .textareaApp textarea {
        font-size: 14px;
    }
}