.sidebarMenu {
    color: white;
}
.sidebarMenu ul {
    list-style-type: none;
    margin-left: -15px;
    margin-right: -15px;
}
.sidebarMenu ul li{
    cursor: pointer;
}
.sidebarMenu ul li span{
    display: block;
    padding: 5px 15px;
    font-weight: bold;
}
.sidebarMenu li > a {
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    display: block;
    width: 100%;
    color: rgb(240 240 241);
    padding: 5px 25px;
    transition: all 0.4s ease;
}
.sidebarMenu li > a:hover {
    background-color: rgb(44 51 56)!important;
}

@media (max-width: 991px){
    .sidebarMenu li {
        margin: 5px 0;
    }
}

