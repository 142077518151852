.app_auth_box {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 30px 30px;
    color: #B0BFC6;
    max-width: 386px;
}
.app_auth_box .app_auth__header {
    margin-bottom: 30px;
    line-height: 22px;
    border-bottom: 1px solid #B0BFC6;
}

.app_auth_box form > button {
    width: 100%;
}

@media (max-width: 767px) {
    .app_auth_box {
        margin: auto;
        padding: 0 0 20px;
    }
}