.input__error{
    font-size: 10px;
    line-height: 16px;
    color: #D62D30;
    position: absolute;
    bottom: -16px;
}
@media(max-width: 500px){
    .input__error{
        font-size: 10px;
        line-height: 12px;
        bottom: -11px;
    }
}