.app__field__row {
    display: flex;
    align-items: center;
}
.app__field__row  .app__input__label {
    width: 145px;
}
.app__field__row .app__input {
    position: relative;
}
.app__field__row  .app__input {
    width: 100%;
}
.app__field__row  .app__input__label+.app__input {
    width: calc(100% - 145px);
}
.app__field__row .app__input input {
    border-radius: 4px;
    width: 100%;
    padding: 9px 15px;
    font-style: normal;
    border: 1px solid #B0BFC6;
    background-color: #F2F2F2;
    outline: none;
    color: #151515;
    font-size: 16px;
    line-height: 22px;
    background-image: none!important;
    transition: border-color 0.15s ease;
}
.app__field__row .app__input input:focus {
    border-color: #1998A7!important;
}
.app__field__row .app__input input.error {
    border-color: #D62D30!important;
}
.app__field__row .app__input .app__input__placeholder {
    color: #B0BFC6;
    position: absolute;
    top: 8px;
    left: 16px;
    text-overflow: ellipsis;
    -webkit-transition: transform 150ms cubic-bezier(0.4,0,0.2,1),opacity 150ms cubic-bezier(0.4,0,0.2,1);
    transition: transform 150ms cubic-bezier(0.4,0,0.2,1),opacity 150ms cubic-bezier(0.4,0,0.2,1);
    white-space: nowrap;
    width: 100%;
    max-width: calc(100% - 30px);
    z-index: 1;
    pointer-events: none;
    font-size: 16px;
    line-height: 22px;
}
.app__field__row .app__input.focused .app__input__placeholder {
    transform: translateY(-10px) translateX(0px);
    line-height: 18px;
    font-size: 11px;
}

@media(max-width: 500px){
    .app__field__row .app__input input {
        font-size: 14px;
        line-height: 19px;
    }
    .app__field__row .app__input .app__input__placeholder {
        font-size: 14px;
        line-height: 19px;
    }

    .app__field__row {
        flex-direction: column;
        margin-bottom: 15px;
    }
    .app__field__row  .app__input__label {
        width: 100%;
        margin-bottom: 5px;
    }
    .app__field__row  .app__input__label+.app__input {
        width: 100%;
    }
}
