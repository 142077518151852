ul.secondLevel {
    list-style-type: disc;
    font-size: 14px;
}
ul.secondLevel > li {
    margin-left: 7px;
}
ul.secondLevel.collapsed {
    display: none;
}
