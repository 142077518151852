ul.seoUlList {
    list-style: disc;
    padding-left: 25px;
    margin: 25px 0;
}
ul.navTabs {
    border-bottom: 1px solid #cccccc;
}
ul.navTabs > li.navItem {
    display: inline-block;
    padding: 7px 14px;
    background-color: #ffffff;
    cursor: pointer;
    font-weight: 600;
    color: #999999;
}
ul.navTabs > li.navItem.active {
    border: 1px solid #cccccc;
    border-bottom: 1px solid #ffffff;
    border-radius: 4px 4px 0 0;
    margin-bottom: -1px;
    color: #1998A7;
}
.tabBlock {
    display: none;
}
.tabBlock.active {
    display: flex;
}
.importWrap {

}
.importBtn{
    margin-top: 25px;
    padding: 5px 35px !important;
    border-radius: 4px;
    color: #ffffff;
    background-color: #1998A7;
    border-color: #1998A7;
    outline: none;
    cursor: pointer;
}
