@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');

body {
  font: 16px 'Open Sans', sans-serif;
}
.border-right{
  border-right: 1px solid #adafae !important;
}
.h-100 {
  height: 100vh;
}
.App {
  height: 100vh;
  font-size: 16px;
}
.AppContent {
  padding-top: 38px;
}
.App-header {
  background: rgb(29 35 39);
  color: white;
  padding:7px;
  height: 38px;
  position: fixed;
  width: 100%;
  z-index: 100;
}
.App-logo {
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
  font-size: 20px;
}
.App-link {
  color: #61dafb;
}

