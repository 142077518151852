.app_btn {
    outline: none;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #adafae;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 1rem;
    font-size: 1rem;
    border-radius: 40px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*primary*/
.primary {
    color: #ffffff;
    min-width: 150px;
    border-radius: 40px;
    background-color: #1998A7;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding: 9px 50px;
}
.primary:hover {
    background-color: #0D6064;
}
@media (hover: none) {
    .primary:hover {
        background-color: #1998A7;
    }
}

/**/
.link {
    color: #1998A7;
}

/*casual*/
.casual {
    color: #151515;
    background: rgba(255, 255, 255, 1);
    border-radius: 38px;
    font-weight: 600;
    padding: 9px 50px;
    font-size: 14px!important;
}

/**login box*/
.loginTab {
    position: relative;
    padding: 10px 0;
    font-size: 16px;
    margin-right: 20px;
}
.loginTab:after {
    content: "";
    position: absolute;
    bottom: -2px;
    right: 0;
    height: 2px;
    width: 100%;
    background: #1998A7;
    opacity: 0;
    transition: all 0.4s ease;
}
.loginTab:last-child {
    margin-right: 0;
}
.loginTab:hover, .loginTab.loginTabActive {
    color: #151515;
}
.loginTab:hover:after, .loginTabActive.loginTab:after {
    opacity: 1!important;
}

.pay {
    color: #ffffff;
    min-width: 150px;
    border-radius: 40px;
    background: transparent;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 9px 42px;
    z-index: 10;
    position: relative;
    overflow: hidden;
}
.pay:before {
    content: "";
    position: absolute;
    transition: opacity .35s ease-in-out;
    -moz-transition: opacity .35s ease-in-out;
    -webkit-transition: opacity .35s ease-in-out;
    top:0; left: 0; right: 0; bottom: 0; z-index:-1;
    background: -moz-linear-gradient(91.04deg, #8E0FE1 32.81%, #BB3AF8 100%);
    background: -webkit-linear-gradient(91.04deg, #8E0FE1 32.81%, #BB3AF8 100%);
    background: linear-gradient(91.04deg, #8E0FE1 32.81%, #BB3AF8 100%);
}
.pay:hover:before {
    opacity: 0;
}
.pay:after {
    content: "";
    position: absolute;
    transition: opacity .35s ease-in-out;
    -moz-transition: opacity .35s ease-in-out;
    -webkit-transition: opacity .35s ease-in-out;
    top:0; left: 0; right: 0; bottom: 0; z-index:-1;opacity: 0;
    background: -moz-linear-gradient(91.04deg, #BB3AF8 32.81%, #8E0FE1 100%);
    background: -webkit-linear-gradient(91.04deg, #BB3AF8 32.81%, #8E0FE1 100%);
    background: linear-gradient(91.04deg, #BB3AF8 32.81%, #8E0FE1 100%);
}
.pay:hover:after {
    opacity: 1;
}

.vip {
    color: #ffffff;
    min-width: 150px;
    border-radius: 40px;
    background: transparent;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 9px 42px;
    z-index: 10;
    position: relative;
    overflow: hidden;
}
.vip:before {
    content: "";
    position: absolute;
    transition: opacity .35s ease-in-out;
    -moz-transition: opacity .35s ease-in-out;
    -webkit-transition: opacity .35s ease-in-out;
    top:0; left: 0; right: 0; bottom: 0; z-index:-1;
    background: -moz-linear-gradient(91.04deg, #FA5293 38.4%, #F86B64 100%);
    background: -webkit-linear-gradient(91.04deg, #FA5293 38.4%, #F86B64 100%);
    background: linear-gradient(91.04deg, #FA5293 38.4%, #F86B64 100%);
}
.vip:hover:before {
    opacity: 0;
}
.vip:after {
    content: "";
    position: absolute;
    transition: opacity .35s ease-in-out;
    -moz-transition: opacity .35s ease-in-out;
    -webkit-transition: opacity .35s ease-in-out;
    top:0; left: 0; right: 0; bottom: 0; z-index:-1;opacity: 0;
    background: -moz-linear-gradient(0deg, #F86B64, #F86B64);
    background: -webkit-linear-gradient(0deg, #F86B64, #F86B64);
    background: linear-gradient(0deg, #F86B64, #F86B64);
}
.vip:hover:after {
    opacity: 1;
}

@media(max-width: 991px){
    .app_btn {
        font-size: 14px;
    }
}